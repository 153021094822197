.branding-page ul {
  list-style-position: inside;
  padding-left: 2rem;
}
.branding-page ul li {
  list-style: disc;
}
.branding-page .content-section {
  border: 2px solid #719500;
  border-radius: 10px;
  padding: 20px 10px;
}