.milestones-page #RequestedReportId {
  margin-bottom: 5px;
}
.milestones-page #report-name {
  margin-bottom: 0;
}
.milestones-page #report-name span {
  font-size: 20px !important;
  font-weight: 400;
}
.milestones-page #loading-indicator .icon {
  color: #292661;
}
.milestones-page #gantt-legend {
  width: 150px;
  position: absolute;
  left: 20px;
  top: 66px;
  z-index: 1000;
  font-size: 11px;
}
.milestones-page #gantt-legend .label {
  margin-left: 8px;
}
.milestones-page #gantt-legend .legend-bar, .milestones-page #gantt-legend .actual-bar, .milestones-page #gantt-legend .planning-bar {
  display: inline-block;
  width: 20px;
  height: 11px;
}
.milestones-page #gantt-legend .legend-icon, .milestones-page #gantt-legend .actual-icon, .milestones-page #gantt-legend .planning-icon {
  display: inline-block;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.milestones-page #gantt-legend .planning-bar {
  background-color: #5dade0;
}
.milestones-page #gantt-legend .planning-icon {
  color: #5dade0;
}
.milestones-page #gantt-legend .actual-bar {
  background-color: #292661;
}
.milestones-page #gantt-legend .actual-icon {
  color: #292661;
}
.milestones-page #milestones-report-table_wrapper {
  margin: 40px 0 30px 0;
}
.milestones-page #milestones-report-table_wrapper tr.dtrg-group td {
  background-color: #292661 !important;
  color: white !important;
}
.milestones-page #milestones-report-table_wrapper .progress.complete .progress-bar {
  background-color: #292661;
}
.milestones-page #milestones-report-table_wrapper .progress.complete .label {
  top: 8px;
  color: white;
  font-weight: 500;
}
.milestones-page #milestones-report-table_wrapper .progress.incomplete .progress-bar {
  background-color: #5dade0;
  color: #292661;
}
.milestones-page #milestones-report-table_wrapper .progress.incomplete .label {
  top: 8px;
  color: #292661;
  font-weight: 500;
}